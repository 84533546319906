import { SITE_IS_JBDA } from '@/config/site.config'
import { I18nValue } from '../i18n/lang-context'

export type GlossaryEntry = {
  name: I18nValue
  shortDesc: I18nValue
  longDesc: I18nValue
}
export type Glossary = { [key: string]: GlossaryEntry }

export const GLOSSARY: Glossary = SITE_IS_JBDA ? {} : {
  amf: {
    name: {
      fr: 'AMF (Autorité des Marchés Financiers)',
      en: 'AMF (Autorité des Marchés Financiers)'
    },
    shortDesc: {
      fr: 'Organisme public chargé de la régulation, de la surveillance et de la protection des marchés financiers en France.',
      en: 'Public agency responsible for the regulation, supervision, and protection of financial markets in France.'
    },
    longDesc: {
      fr: 'C\'est l\'organisme public français chargé de la régulation, de la surveillance et de la protection des marchés financiers. Sa mission principale est d\'assurer le bon fonctionnement des marchés et de veiller à la protection des investisseurs. L\'AMF dispose de pouvoirs de contrôle, de sanction et de réglementation, notamment dans les domaines de l\'offre de produits financiers, des activités des prestataires de services financiers et de la conformité des acteurs aux règles en vigueur. Elle supervise également les Prestataires de Services sur Actifs Numériques (PSAN).',
      en: 'This is the French public body responsible for regulating, supervising, and protecting financial markets. Its primary mission is to ensure the smooth functioning of markets and to safeguard investors. The AMF has control, sanction, and regulatory powers, particularly in areas such as financial product offerings, financial service providers’ activities, and ensuring compliance with existing rules. It also supervises Digital Asset Service Providers (PSAN).'
    }
  },
  aml: {
    name: {
      fr: 'AML (Anti Money Laundering)',
      en: 'AML (Anti Money Laundering)'
    },
    shortDesc: {
      fr: 'Ensemble de mesures visant à prévenir, détecter et lutter contre le blanchiment d\'argent.',
      en: 'A set of measures aimed at preventing, detecting, and combating money laundering.'
    },
    longDesc: {
      fr: 'La lutte contre le blanchiment d\'argent désigne l\'ensemble des lois, règlements et procédures visant à prévenir et détecter les activités de blanchiment d\'argent. Le blanchiment consiste à dissimuler l\'origine illégale de fonds en les intégrant dans le système financier de manière à les rendre légitimes. Les règles AML imposent aux institutions financières et aux entités comme les PSAN de mettre en place des mesures de contrôle, telles que l\'identification des clients (KYC) et la surveillance des transactions (KYT), pour signaler les opérations suspectes. Le non-respect des règles AML expose les institutions à des sanctions, incluant amendes et interdictions d\'exercer.',
      en: 'Anti-money laundering refers to all laws, regulations, and procedures aimed at preventing and detecting money-laundering activities. Money laundering involves concealing the illegal origin of funds by integrating them into the financial system to legitimize them. AML rules require financial institutions and entities such as PSAN to implement control measures, such as customer identification (KYC) and transaction monitoring (KYT), to report suspicious activities. Failure to comply with AML regulations exposes institutions to sanctions, including fines and bans on operating.'
    }
  },
  blockchain: {
    name: {
      fr: 'Blockchain',
      en: 'Blockchain'
    },
    shortDesc: {
      fr: 'Technologie de transmission et de stockage de données distribuée et sécurisée.',
      en: 'Distributed and secure data storage and transmission technology.'
    },
    longDesc: {
      fr: 'La blockchain est une base de données distribuée, immuable et sans intermédiaire de confiance. Elle permet d\'enregistrer des transactions de manière sécurisée. Elle est surtout connue pour être la technologie qui sous-tend les cryptomonnaies comme le Bitcoin, mais elle a aussi des applications dans de nombreux autres domaines, comme la gestion de la chaîne d\'approvisionnement, le vote électronique ou la certification des données.',
      en: 'Blockchain is a distributed, immutable database without third-party intermediaries. It enables transactions to be recorded securely. It is best known as the technology behind cryptocurrencies like Bitcoin, but it also has applications in many other areas, such as supply chain management, electronic voting, or data certification.'
    }
  },
  conformite: {
    name: {
      fr: 'Conformité',
      en: 'Compliance'
    },
    shortDesc: {
      fr: 'Respect des lois, règlements, normes et pratiques en vigueur.',
      en: 'Adherence to applicable laws, regulations, standards, and practices.'
    },
    longDesc: {
      fr: 'La conformité, dans un contexte juridique et financier, désigne le respect par une entreprise ou une organisation des lois, règlements, normes et pratiques en vigueur qui encadrent ses activités. Cela inclut l\'adhésion aux règles imposées par les régulateurs comme l\'AMF. La conformité vise à prévenir les comportements illégaux ou contraires à l\'éthique, notamment en matière de fraude, de blanchiment d’argent ou de non-respect des obligations fiscales et sociales. Elle implique également la mise en place de procédures internes pour détecter, corriger et éviter les risques juridiques ou réglementaires.',
      en: 'Compliance, in a legal and financial context, refers to an organization’s or company’s adherence to the applicable laws, regulations, standards, and practices governing its activities. This includes complying with rules imposed by regulators such as the AMF. Compliance aims to prevent illegal or unethical behavior, notably in areas such as fraud, money laundering, and non-compliance with tax and social obligations. It also involves setting up internal procedures to detect, correct, and avoid legal or regulatory risks.'
    }
  },
  crypto_actifs: {
    name: {
      fr: 'Crypto-actifs',
      en: 'Crypto-assets'
    },
    shortDesc: {
      fr: 'Actifs numériques et décentralisés fonctionnant grâce à la blockchain.',
      en: 'Decentralized digital assets operating on blockchain.'
    },
    longDesc: {
      fr: 'Les crypto-actifs comme le Bitcoin ou l’Ethereum permettent des transactions sécurisées sans intermédiaires financiers. Ils constituent une classe d’actifs unique, indépendante des monnaies fiat.',
      en: 'Crypto-assets such as Bitcoin or Ethereum enable secure transactions without financial intermediaries. They constitute a unique asset class, independent of fiat currencies.'
    }
  },
  crypto_due_diligence: {
    name: {
      fr: 'Crypto due diligence',
      en: 'Crypto Due Diligence'
    },
    shortDesc: {
      fr: 'Vérification de l\'historique d\'une crypto pour détecter d\'éventuelles activités illicites.',
      en: 'Verification of a cryptocurrency’s history to detect potential illicit activities.'
    },
    longDesc: {
      fr: 'La crypto due diligence ou « vérification préalable en crypto-actifs » consiste à examiner l\'historique d\'une cryptomonnaie pour déterminer si elle a été impliquée dans des activités illicites telles que le blanchiment d\'argent, le financement du terrorisme ou d\'autres crimes financiers. Un aspect clé de cette analyse est la notion de « crypto tracing » (traçage des cryptomonnaies), réalisée par des entreprises spécialisées en KYT, qui utilisent des outils de suivi pour identifier l\'origine des fonds et évaluer leur « propreté ». Ces analyses permettent d’évaluer le risque associé à une transaction en traçant les flux de crypto-actifs à travers différents portefeuilles.',
      en: 'Crypto due diligence consists of examining a cryptocurrency’s history to determine whether it has been involved in illicit activities such as money laundering, terrorism financing, or other financial crimes. A key aspect of this analysis is "crypto tracing," conducted by specialized KYT companies that use tracking tools to identify the origin of funds and assess their "cleanliness." These analyses help evaluate the risk associated with a transaction by tracing crypto-asset flows across different wallets.'
    }
  },
  custody: {
    name: {
      fr: 'Custody (Conservation)',
      en: 'Custody'
    },
    shortDesc: {
      fr: 'Service de conservation sécurisée des actifs numériques pour le compte de clients.',
      en: 'Secure custody service of digital assets on behalf of clients.'
    },
    longDesc: {
      fr: 'Service offert par des institutions spécialisées pour conserver de manière sécurisée des actifs numériques au nom de leurs clients. Les solutions de custody incluent des technologies avancées comme les portefeuilles numériques à signatures multiples ou la conservation hors ligne (cold storage) pour protéger les actifs contre les cyberattaques.',
      en: 'Service offered by specialized institutions to securely hold digital assets on behalf of their clients. Custody solutions include advanced technologies such as multi-signature digital wallets or offline storage (cold storage) to protect assets from cyberattacks.'
    }
  },
  fiat: {
    name: {
      fr: 'Fiat (monnaie ou devise)',
      en: 'Fiat (currency)'
    },
    shortDesc: {
      fr: 'Monnaie ayant cours légal.',
      en: 'Legal tender currency'
    },
    longDesc: {
      fr: 'En finance et en économie, le terme « fiat » est utilisé pour désigner une monnaie qui n’est pas adossée à une réserve d’une autre marchandise comme l’or, l’argent ou le pétrole. Une monnaie fiat a une valeur intrinsèque simplement parce qu’elle est déclarée comme ayant cours légal par une autorité gouvernementale. Les monnaies fiat sont régies par des lois créées par les gouvernements qui déterminent leur émission, leur distribution, leur valeur et leur utilisation légale.\nLa plupart des monnaies dans le monde sont des monnaies fiat. C’est le cas du dollar américain, de l’euro, de la livre sterling, du yen japonais, du yuan chinois… Les monnaies fiat sont utilisées dans la plupart des transactions financières, des achats quotidiens aux investissements à grande échelle.',
      en: 'In finance and economics, the term "fiat" is used to refer to a currency that is not backed by a reserve of another commodity such as gold, silver or oil. A fiat currency has intrinsic value simply because it is declared legal tender by a government authority. Fiat currencies are governed by laws created by governments that determine their issuance, distribution, value, and legal use.\nMost currencies in the world are fiat currencies. This is the case for the US dollar, the euro, the pound sterling, the Japanese yen, the Chinese yuan… Fiat currencies are used in most financial transactions, from daily purchases to large-scale investments.'
    }
  },
  fiducie: {
    name: {
      fr: 'Fiducie',
      en: 'Trust'
    },
    shortDesc: {
      fr: 'Contrat où des biens sont transférés à un fiduciaire pour le bénéfice d\'un tiers.',
      en: 'Contract where assets are transferred to a trustee for the benefit of a third party.'
    },
    longDesc: {
      fr: 'Contrat par lequel un constituant transfère des biens, comme des crypto-actifs, au profit d\'un bénéficiaire qui les gère, ce bénéficiaire est appelé fiduciaire. C’est généralement une entreprise ou une personne spécialisée dans la gestion financière, comptable et administrative pour le compte d\'autrui, en particulier des entreprises. Une fiduciaire peut offrir divers services, tels que la comptabilité, la gestion des salaires, la fiscalité, le conseil financier et l\'audit.',
      en: 'Contract in which a settlor transfers assets, such as crypto-assets, to a trustee who manages them for the benefit of a beneficiary. This trustee is usually a company or person specialized in financial, accounting, and administrative management on behalf of others, particularly businesses. A trustee can offer various services, such as accounting, payroll management, taxation, financial consulting, and auditing.'
    }
  },
  flat_tax: {
    name: {
      fr: 'Flat tax',
      en: 'Flat Tax'
    },
    shortDesc: {
      fr: 'Impôt forfaitaire sur les revenus du capital comme les plus-values résultant de la vente de crypto-actifs.',
      en: 'Fixed tax on capital gains, applicable on crypto-assets.'
    },
    longDesc: {
      fr: 'Impôt forfaitaire prélevé sur les plus-values générées par la vente de crypto-actifs. En France, cet impôt est de 30% à mi-2024 et englobe à la fois l’impôt sur le revenu et les prélèvements sociaux.',
      en: 'Fixed tax levied on capital gains generated by the sale of crypto-assets. In France, this tax is 30% as of mid-2024 and includes both income tax and social contributions.'
    }
  },
  kyc: {
    name: {
      fr: 'KYC (Know Your Customer)',
      en: 'KYC (Know Your Customer)'
    },
    shortDesc: {
      fr: 'Procédure de vérification de l\'identité des clients pour prévenir les risques financiers.',
      en: 'Client identity verification procedure to mitigate financial risks.'
    },
    longDesc: {
      fr: '« Connaître son client » est une procédure de vérification de l\'identité des clients imposée aux institutions financières et autres entités réglementées. Cette démarche vise à prévenir les risques liés au blanchiment d\'argent, au financement du terrorisme et à d\'autres activités illicites. Le KYC implique de s\'assurer que les fonds et les activités des clients sont légitimes. Les obligations KYC sont souvent renforcées par des régulations nationales et internationales pour garantir la sécurité et l\'intégrité des transactions financières.',
      en: '“Know Your Customer” is a client identity verification procedure imposed on financial institutions and other regulated entities. This process aims to mitigate risks related to money laundering, terrorism financing, and other illicit activities. KYC involves ensuring that clients\' funds and activities are legitimate. KYC obligations are often reinforced by national and international regulations to guarantee transaction security and integrity.'
    }
  },
  kyt: {
    name: {
      fr: 'KYT (Know Your Transaction)',
      en: 'KYT (Know Your Transaction)'
    },
    shortDesc: {
      fr: 'Surveillance des transactions pour prévenir des activités illicites.',
      en: 'Transaction monitoring to prevent illicit activities.'
    },
    longDesc: {
      fr: '« Connaître sa transaction » est une procédure de surveillance et d’analyse des transactions financières, principalement utilisée dans le domaine des crypto-actifs. Contrairement à la procédure KYC (Know Your Customer), qui se concentre sur l’identification des clients, le KYT vise à examiner les transactions elles-mêmes pour détecter des comportements suspects ou potentiellement frauduleux. Cette méthode permet de suivre les mouvements de fonds en temps réel et de prévenir le blanchiment d’argent, le financement du terrorisme ou d\'autres activités illicites en surveillant les transactions pour s\'assurer de leur conformité aux normes légales et réglementaires.',
      en: '“Know Your Transaction” is a procedure for monitoring and analyzing financial transactions, mainly used in the field of crypto-assets. Unlike KYC, which focuses on client identification, KYT examines the transactions themselves to detect suspicious or potentially fraudulent behavior. This method enables real-time tracking of fund movements to prevent money laundering, terrorism financing, or other illicit activities by monitoring transactions to ensure compliance with legal and regulatory standards.'
    }
  },
  mica: {
    name: {
      fr: 'MiCA (Markets in Crypto-Assets)',
      en: 'MiCA (Markets in Crypto-Assets)'
    },
    shortDesc: {
      fr: 'Règlement européen sur les crypto-actifs',
      en: 'European Regulation on Crypto-Assets.'
    },
    longDesc: {
      fr: 'Cette réglementation européenne vise à encadrer les émissions et les services sur crypto-actifs qui ne relèvent pas des réglementations existantes en matière d’instruments et de produits financiers, en créant un cadre réglementaire européen harmonisé. Elle remplace les réglementations nationales mises en place par certains États membres de l’Union européenne.\nEntrée en vigueur le 29 juin 2023, MiCA est applicable entièrement à partir du 30 décembre 2024.',
      en: 'This European regulation aims to govern the issuance of crypto-assets and related services that fall outside the scope of existing regulations on financial instruments and products, by establishing a harmonized regulatory framework across Europe. It replaces the national regulations previously adopted by certain EU member states.\nMiCA came into force on 29 June 2023, and is fully applicable from 30 December 2024.'
    }
  },
  off_ramp: {
    name: {
      fr: 'Off-ramp',
      en: 'Off-ramp'
    },
    shortDesc: {
      fr: 'Conversion d’actifs numériques en monnaie fiat.',
      en: 'Conversion of digital assets into fiat currency.'
    },
    longDesc: {
      fr: 'Processus par lequel les actifs numériques, tels que les crypto-actifs, sont convertis en monnaie fiat, comme l’euro ou le dollar. L’off-ramp est une étape clé pour les détenteurs de crypto-actifs souhaitant utiliser leurs liquidités dans l’économie réelle.',
      en: 'The process by which digital assets, such as crypto-assets, are converted into fiat currency, such as euros or dollars. Off-ramping is a key step for crypto-asset holders seeking to use their liquidity in the real economy.'
    }
  },
  tax_trigger: {
    name: {
      fr: 'Opération imposable (fait générateur d\'imposition)',
      en: 'Taxable transaction (or taxable event)'
    },
    shortDesc: {
      fr: 'Opération déclenchant la taxation',
      en: 'Operation triggering taxation'
    },
    longDesc: {
      fr: 'En France, le fait générateur d\'impôts en ce qui concernce les crypto-actifs est leur transfert de propriété à autrui. Cela inclu donc leur vente contre toute monnaie ayant cours légal ainsi que leur utilisation pour tout achat de biens ou de services.\nPar contre, l\'échange d\'une crypto contre une autre ne rentre pas dans ce cadre et ne déclenche donc pas de fiscalité. C\'est pourquoi lors de la période d\'un séquestre, l\'opération reste fiscalement neutre car la propriété n\'est pas transférée jusqu\'à sa résolution.',
      en: 'In France, the tax-generating event with regard to crypto-assets is their transfer of ownership to others. This therefore includes their sale for any fiat currency as well as their use for any purchase of goods or services.\nOn the other hand, the exchange of one crypto for another does not fall within this framework and therefore does not trigger any taxation. This is why during the period of a sequestration, the transaction remains fiscally neutral due to ownership not being transferred until its resolution.'
    }
  },
  plus_value: {
    name: {
      fr: 'Plus-value',
      en: 'Capital Gain'
    },
    shortDesc: {
      fr: 'Gain sur la vente d’un actif numérique à un prix supérieur à celui d’achat.',
      en: 'Profit from the sale of a digital asset at a price higher than its purchase price.'
    },
    longDesc: {
      fr: 'Gain réalisé lors de la vente ou de la conversion d\'un actif numérique à un prix supérieur à celui auquel il a été acheté. Les plus-values sur les cryptomonnaies sont soumises à l’imposition, notamment à travers la flat tax.',
      en: 'A profit earned from the sale or conversion of a digital asset at a price higher than its purchase price. Capital gains on cryptocurrencies are subject to taxation, notably through the flat tax.'
    }
  },
  psan_enregistrement: {
    name: {
      fr: 'PSAN Enregistrement (Prestataire de Services sur Actifs Numériques)',
      en: 'PSAN Registration (Digital Asset Service Provider)'
    },
    shortDesc: {
      fr: 'Enregistrement obligatoire auprès de l\'Autorité des Marchés Financiers (AMF) pour pouvoir fournir des services sur actifs numériques.',
      en: 'Mandatory registration with the Financial Markets Authority (AMF) to provide digital asset services.'
    },
    longDesc: {
      fr: 'PSAN signifie « Prestataire de Services sur Actifs Numériques ». En France, c\'est un statut créé par l\'Autorité des Marchés Financiers (AMF) pour encadrer les acteurs du secteur des cryptomonnaies et autres actifs numériques. Ce statut est obligatoire pour les entreprises qui proposent certains types de services autour des actifs numériques, comme l’achat, la vente, la conservation, l’échange ou la négociation d’actifs numériques. Le PSAN vise à assurer la sécurité des utilisateurs et à limiter les risques de blanchiment d\'argent et de financement du terrorisme.',
      en: 'PSAN stands for "Digital Asset Service Provider" in France, a status established by the Financial Markets Authority (AMF) to regulate players in the cryptocurrency and other digital asset sectors. This status is mandatory for companies offering certain types of services related to digital assets, such as facilitating the purchase, sale, storage, exchange, or trading of digital assets. The PSAN status aims to ensure user security and limit risks associated with money laundering and terrorist financing.'
    }
  },
  psan_agrement: {
    name: {
      fr: 'PSAN Agrément (Prestataire de Services sur Actifs Numériques)',
      en: 'PSAN License (Digital Asset Service Provider)'
    },
    shortDesc: {
      fr: 'L\'agrément PSAN est optionnel. Délivré par l\'AMF, il atteste de la conformité renforcée des prestataires de services sur actifs numériques.',
      en: 'The PSAN license is optional. Issued by the AMF, it certifies the enhanced compliance of digital asset service providers.'
    },
    longDesc: {
      fr: 'À la différence de l’enregistrement qui est obligatoire pour les sociétés qui exercent certaines activités, l’agrément PSAN est optionnel. Il permet de garantir que l’entreprise agréée a mis en place des mesures de protection renforcées. Pour obtenir cet agrément, il est nécessaire de mettre en place des règles strictes concernant les ressources financières, l’organisation interne et la transparence envers les clients. Les exigences pour l’agrément PSAN sont ainsi plus rigoureuses que celles requises pour l’enregistrement PSAN. Elles s’alignent également sur les dispositions prévues par le règlement MiCA.',
      en: 'Unlike the registration, which is mandatory for companies conducting certain activities, the PSAN license is optional. It certifies that the licensed company has implemented enhanced protection measures. To obtain this license, strict rules must be followed regarding financial resources, internal organization, and transparency toward clients. The requirements for the PSAN license are therefore more stringent than those for PSAN registration and align with provisions set out by the MiCA regulation.'
    }
  },
  client_qualification: {
    name: {
      fr: 'Qualification client',
      en: 'Client qualification'
    },
    shortDesc: {
      fr: 'Ensemble de vérifications effectuées en amont de l\'opération et visant à évaluer le sérieux du dossier',
      en: 'A set of checks performed before the operation to assess the credibility of the case.'
    },
    longDesc: {
      fr: 'Chez Legibloq, la qualification client consiste à filtrer les projets en amont. Nous nous assurons de la solidité des dossiers et commençons la collecte des documents nécessaires aux étapes suivantes. Ce processus inclut l\'analyse de la qualité du projet, l\'identification du client et une vérification de l\'origine de ses fonds. L\'objectif est de faciliter la collaboration avec les partenaires tels que les notaires, avocats, et professionnels de l\'immobilier, et de garantir une transaction efficace et conforme pour toutes les parties impliquées.',
      en: 'At Legibloq, client qualification involves screening projects early in the process. We ensure that each application is robust and begin collecting the necessary documents for subsequent steps. This process includes evaluating the quality of the project, verifying the client\'s identity, and checking the origin of their funds. The goal is to facilitate collaboration with partners such as notaries, lawyers, and real estate professionals, ensuring an efficient and compliant transaction for all parties involved.'
    }
  },  
  regulation: {
    name: {
      fr: 'Régulation',
      en: 'Regulation'
    },
    shortDesc: {
      fr: 'Législation encadrant les activités liées aux actifs numériques.',
      en: 'Legislation governing activities related to digital assets.'
    },
    longDesc: {
      fr: 'Ensemble des lois et des règlements qui encadrent les activités liées aux actifs numériques, incluant leur émission, leur conservation, leur vente ainsi que les services offerts par les PSAN. La régulation assure la transparence et la sécurité des transactions. Dans le domaine des actifs numériques, la régulation vise à protéger les investisseurs, à prévenir les abus de marché, à lutter contre le blanchiment d\'argent et le financement du terrorisme, tout en veillant à la conformité des acteurs aux lois en vigueur.',
      en: 'A set of laws and regulations governing activities related to digital assets, including their issuance, custody, sale, and the services offered by PSANs. Regulation ensures transparency and transaction security. In the field of digital assets, regulation aims to protect investors, prevent market abuse, combat money laundering, and counter-terrorism financing, while ensuring that actors comply with applicable laws.'
    }
  },
  sequestre: {
    name: {
      fr: 'Séquestre',
      en: 'Escrow'
    },
    shortDesc: {
      fr: 'Détention temporaire de biens par une personne ou entité désignée.',
      en: 'Temporary holding of assets by a designated person or entity.'
    },
    longDesc: {
      fr: 'Le séquestre est une procédure juridique par laquelle une personne ou une entité, appelée le séquestre, est désignée pour détenir temporairement des biens ou des fonds jusqu\'à la réalisation d’une condition prédéfinie, généralement la finalisation d\'une transaction ou la résolution d\'un litige. Le séquestre est utilisé pour protéger les intérêts des parties dans une transaction ou en litige, en garantissant que les actifs sont conservés en sécurité et ne sont libérés qu\'une fois les obligations remplies. Cette mesure assure une sécurité juridique tout au long du processus.',
      en: 'Escrow is a legal procedure whereby a person or entity, known as the escrow agent, is designated to temporarily hold assets or funds until a predetermined condition is met, usually the completion of a transaction or resolution of a dispute. Escrow is used to protect the parties\' interests in a transaction or dispute, ensuring that assets are held securely and only released once obligations are fulfilled. This measure provides legal security throughout the process.'
    }
  },
  stablecoin: {
    name: {
      fr: 'Stablecoin',
      en: 'Stablecoin'
    },
    shortDesc: {
      fr: 'Crypto-actif stable conçu pour éviter la volatilité des marchés.',
      en: 'Stable crypto-asset designed to avoid market volatility.'
    },
    longDesc: {
      fr: 'C’est un crypto-actif conçu pour maintenir une valeur stable, contrairement aux autres crypto-actifs souvent sujets à de fortes fluctuations. Les stablecoins sont généralement adossés à des actifs de réserve comme des monnaies fiat (euro, dollar, etc.), des matières premières (comme l\'or) ou un panier d\'actifs divers. Il existe des stablecoins algorithmiques dont la stabilité est assurée automatiquement par des algorithmes mathématiques. Leur objectif est de fournir les avantages des crypto-actifs (rapidité, décentralisation) tout en offrant une stabilité de prix, ce qui les rend adaptés aux transactions et à la gestion de fonds sans subir la volatilité des marchés des crypto-actifs classiques.',
      en: 'A stablecoin is a crypto-asset designed to maintain a stable value, in contrast to other crypto-assets often subject to significant fluctuations. Stablecoins are generally backed by reserve assets such as fiat currencies (euro, dollar, etc.), commodities (like gold), or a basket of various assets. There are also algorithmic stablecoins, where stability is automatically maintained by mathematical algorithms. Their goal is to provide the advantages of crypto-assets (speed, decentralization) while offering price stability, making them suitable for transactions and fund management without facing the volatility typically associated with traditional crypto-asset markets.'
    }
  },
  surcollateralisation: {
    name: {
      fr: 'Surcollatéralisation (dans le cadre d’un séquestre)',
      en: 'Overcollateralization (as used in escrow mechanism)'
    },
    shortDesc: {
      fr: 'Apport d’actifs dépassant de manière significative la valeur fiat à séquestrer.',
      en: 'Deposit of assets significantly exceeding the value in fiat to be sequestered.'
    },
    longDesc: {
      fr: 'Dans le cadre d’un séquestre de cryptomonnaies, leurs valeurs sont amenées à fluctuer dans le temps. Pour permettre d’assurer une transaction d’un montant prédéterminé en euro, il convient donc de bloquer une quantité supérieure d’actifs. Le ratio de surcollatéralisation dépendra des actifs, de leur historique de fluctuation et du risque associé.',
      en: 'As part of a cryptocurrency escrow, their values ​​are likely to fluctuate over time. To ensure a transaction of a predetermined amount in euros, it is therefore necessary to block a greater quantity of assets. The overcollateralization ratio will depend on the assets, their fluctuation history and the associated risk.'
    }
  }
}
