'use client'

import { createContext, useContext, useEffect, useRef, useState } from 'react'

export type TooltipContextType = {
  showTooltip: (content: String, triggerRect: DOMRect) => void
  hideTooltip: () => void
}

const TooltipContext = createContext<TooltipContextType>(undefined as any)

export const TooltipContextProvider = ({ children }: { children: React.ReactNode }) => {

  const [visible, setVisible] = useState(false)
  const [tooltipStyle, setTooltipStyle] = useState<React.CSSProperties>({})
  const [content, setContent] = useState<String | undefined>(undefined)
  const [triggerRect, setTriggerRect] = useState<DOMRect | null>(null)
  const tooltipRef = useRef<HTMLDivElement>(null)

  const showTooltip = (content: String, triggerRect: DOMRect) => {
    setContent(content)
    setTriggerRect(triggerRect)
  }

  const hideTooltip = () => {
    setVisible(false)
  }

  useEffect(() => {
    if (triggerRect) {
      setPosition(triggerRect)
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [triggerRect])

  const setPosition = (triggerRect: DOMRect) => {
    if (!triggerRect || !tooltipRef.current) return

    const tooltipRect = tooltipRef.current.getBoundingClientRect()
    const windowWidth = window.innerWidth

    // Calculate default tooltip position
    let top = triggerRect.top - tooltipRect.height - 10 // 10px margin
    let left = triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2

    // Adjust if the tooltip goes out of bounds
    if (top < 0) top = triggerRect.bottom + 10 // Place below if there's no space above
    if (left < 0) left = 10 // Adjust if too far left
    if (left + tooltipRect.width > windowWidth) left = windowWidth - tooltipRect.width - 10 // Adjust if too far right

    setTooltipStyle({ top: `${top}px`, left: `${left}px` })
  }

  return (
    <TooltipContext.Provider value={{ showTooltip, hideTooltip }}>
      {children}
      {<div
        ref={tooltipRef}
        style={{
          visibility: visible ? 'visible' : 'hidden',
          maxWidth: '90%',
          ...tooltipStyle
        }}
        className='fixed z-50 rounded-sm bg-white px-2 py-1 text-xs text-black shadow-lg'
      >
        {content}
      </div>}
    </TooltipContext.Provider>
  )
}

export function useTooltip(): TooltipContextType {
  const context = useContext(TooltipContext)
  if (!context) throw new Error('useTooltip must be used within a TooltipContextProvider')
  return context
}
