'use client'

import { useTooltip } from '@/contexts/TooltipContext'
import React, { useRef } from 'react'

interface TooltipProps {
  children: React.ReactNode
  content: string
}

const Tooltip: React.FC<TooltipProps> = ({ children, content }) => {

  const tooltip = useTooltip()
  const triggerRef = useRef<HTMLDivElement>(null)

  const handleMouseOver = () => {
    if (!triggerRef.current) return
    const triggerRect = triggerRef.current.getBoundingClientRect()
    tooltip.showTooltip(content, triggerRect)
  }

  const handleMouseOut = () => {
    tooltip.hideTooltip()
  }

  return (
    <span
      ref={triggerRef}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      style={{ display: 'inline-block' }} // ?
    >
      {children}
    </span>
  )
}

export default Tooltip
